@import "../../node_modules/@mdxeditor/editor/dist/style.css";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: #ddd;
  scrollbar-width: 10px;
  scrollbar-color: #a3a3a3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(196, 196, 196);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(230, 230, 230);
}

::-webkit-scrollbar-track {
  background: rgb(53, 53, 53);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 1rem;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  color: inherit;
}

h1 {
  font-size: 3rem;
  letter-spacing: 1rem;
}

p, li {
  font-family: 'EB Garamond', sans-serif;
  font-size: 1.45em;
}

a {
  font-family: 'EB Garamond', sans-serif;
  transition: color .25s ease-in-out;
  color: inherit;
}

a:hover,
a:active {
  color: #7d79cb;
}

.greyed {
  color: #777777;
  transition: color .5s ease;
}

.active {
  color: #ffffff;
  transition: color .5s ease;
}

.full {
  min-height: 100vh;
  max-height: 100%;
  min-width: 100%;
  max-width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
}

input, select, button {
  padding: .75rem;
  background-color: transparent;
  color: #ddd;
  border-radius: 5px;
  border: 1px solid #ddd;
  outline: none;
  transition: border .2s ease-in-out;
  transition: background-color .2s ease-in-out;
  font-family: 'EB Garamond', sans-serif;
  font-size: 1.1rem;
}

button {
  cursor: pointer;
}

select {
  cursor: pointer;
}

option {
  background-color: black;
  color: white;
}

input:focus, select:focus {
  border: 1px solid #7d79cb;
}

::placeholder {
  color: #777;
}

.invalid-input {
  border: 1px solid rgb(209, 71, 71);
}

.valid-input {
  border: 1px solid rgb(82, 155, 82);
}

.invalid {
  color: rgb(209, 71, 71);
}

.label-title {
  font-size: 1.35rem;
  margin-block-end: .4rem;
}

.label {
  font-size: .9rem;
  margin-block-start: .25rem;
}

.text-center {
  text-align: center;
}

button:hover {
  border: 1px solid #7d79cb;
  background-color: #7d79cb;
}

.delete {
  color: rgb(209, 71, 71);
}

.delete:hover {
  border: 1px solid rgb(209, 71, 71);
  background-color: rgb(209, 71, 71);
  color:white;
}

.card {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  max-width: 100%;
  border: 1px solid #fff;
  border-radius: 5px;
  transition: border .2s ease-in-out;
}

.card:hover {
  border: 1px solid #7d79cb;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1)
}

.dark-editor {
  --basePageBg: var(--slate-3);
  --accentBorder: #ddd;
  --accentBorderHover: #7d79cb;
  --accentSolidHover: transparent;
  --baseSolidHover: transparent;
  --baseBase: inherit;
}

.mdxeditor {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.mdxeditor-popup-container {
  background-color: black !important;
  /* border: 1px solid #ddd; */
}

div {
  box-sizing: border-box;
}

.logo {
  max-height: 4rem;
  transition: .35s filter linear;
}

.logo:hover {
  filter: brightness(1.3);
  transition: .35s filter linear;
}

.note {
  font-size: .9em;
  font-style: italic;
}

.footer-text {
  font-size: 1em;
}

.colored {
  color: #7d79cb;
}